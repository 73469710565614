<template>
  <div class="pedidoVue" :style="`min-height:${minHeight}px;`">
    <div class="container-fluid" :style="`height:${minHeight}px;`">
      <div class="row h-100">
        <esquerdo :pedido.sync="pedido" />
        <direito :pedido.sync="pedido" />
      </div>
    </div>
  </div>
</template>

<script>
import esquerdo from "@/components/pedido/esquerdo";
import direito from "@/components/pedido/direito";

export default {
  components: {
    esquerdo,
    direito
  },
  data() {
    return {
      minHeight: window.innerHeight - 140
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.getWindowHeight);
    });
  },
  methods: {
    getWindowHeight() {
      var h = document.documentElement.clientHeight;

      this.minHeight = h - 140;
    }
  },
  computed: {
    pedido() {
      return this.$store.state.pedidos.pedidos;
    }
  }
};
</script>

<style>
</style>