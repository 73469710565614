<template>
  <div>
    <div
      class="modal fade"
      id="showModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content color">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Tem certeza que quer descartar este pedido?
            </h5>
            <!-- <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button> -->
          </div>
          <div class="containerBody">
            <div>
              <div class="containers">
                <input
                  class="radios"
                  value="1"
                  v-model="toggle"
                  type="radio"
                  id="yes"
                />
                <label class="label" for="yes">Sim!</label>
              </div>
              <div class="containers">
                <input
                  class="radios"
                  value="0"
                  v-model="toggle"
                  type="radio"
                  id="no"
                />
                <label class="label" for="no">Não! Voltar</label>
              </div>
            </div>

            <transition name="fadesTransition">
              <section v-if="toggle == 1">
                <form v-for="item in options" :key="item.id">
                  <input
                    class="radios"
                    type="radio"
                    :value="item.value"
                    v-model="newValue"
                    :id="item.id"
                  />
                  <label class="label" :for="item.id">{{ item.value }}</label>
                </form>
              </section>
            </transition>

            <transition name="fadesTransition">
              <div class="justificar" v-if="newValue == 'Outro' && toggle == 1">
                <textarea
                  placeholder="Justificativa..."
                  class="text"
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  maxlength="100"
                  v-model='text'
                >
                </textarea>
                <p>máximo de {{contador}} caracteres </p>
              </div>
            </transition>
            <div class="modal-footer containerFooter">
              <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
              <button type="button" class="btn_default" @click="send">
                Enviar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HTTP from "@/api_system";
export default {
  props: ['id'],
  data() {
    return {
      options: [
        {
          id: 1,
          value: "Cliente desistiu da compra",
        },
        {
          id: 2,
          value: "Cliente não tem recursos para pagar",
        },
        {
          id: 3,
          value: "Serviço(s) adicionado(s) equivocadamente",
        },
        {
          id: 4,
          value: "Serviço não concluído com sucesso",
        },
        {
          id: 5,
          value: "Outro",
        },
      ],
      newValue: undefined,
      text: undefined,
      toggle: undefined,
      contador: 100,
      valuesContador: 0
    };
  },
  watch: {
    'text': function(value) {
      if (value.length > this.valuesContador) {
        this.contador = this.contador - 1
        this.valuesContador = value.length
      } else if (value.length < this.valuesContador) {
        this.contador = this.contador + 1
        this.valuesContador = value.length
      }
    }
  },
  methods: {
    values(values) {
      alert(values);
    },
    send() {

      let values = this.toggle;
      let fields = this.newValue
      let text = this.text
      this.$store.commit("changeOrderId", '')
      if (values == 0) {

        $("#showModal").modal("hide");

      } else {

        if(fields == 'Outro') {

          if(text) {
            this.deletePedidos(text)
          }
          else {
            	this.$notify({
                group: "erros",
                type: "error",
                text: `<i class="icon ion-close-circled"></i>Justifique o seu discarte!`
              });
          }

        }else {
         if(fields != undefined) {

           this.deletePedidos(fields)

         }else {
	          this.$notify({
              group: "erros",
              type: "error",
              text: `<i class="icon ion-close-circled"></i>Selecione uma das alternativas!`
            });

         }
        }
      }
    },
    deletePedidos(message) {
      HTTP.post(
			`services/app/Order/DiscardOrder?orderId=${this.id}&MessageCancelation=${message}`,
			"",
			{
				headers: {
					Authorization: `Bearer ${this.$store.getters.getterToken}`
				}
			}
		)
			.then(response => {
				this.$notify({
					group: "erros",
					type: "error",
					text: `<i class="icon ion-close-circled"></i>Pedido Cancelado!`
				});

          $("#showModal").modal("hide");

				setTimeout(() => {
					this.$store.commit("set_pedido", "");
					this.$router.push("/pedidos");
				}, 2000);
			})
			.catch(error => {
				this.$notify({
					group: "erros",
					type: "error",
					text: `<i class="icon ion-close-circled"></i>${error.response.data.error.message}`
				});
			});
    }
  },
};
</script>

<style scoped>
.color {
  background: #2474b2;
  border-radius: 15px;
  color: #fff;
}

.modal-dialog {
  max-width: 595px !important;
}
.containerBody {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.containerBody section {
  width: 70%;
}

.containerBody > div {
  display: flex;
  justify-content: space-evenly;
  width: 70%;
  align-items: center;
  margin: 20px;
}

.containerBody > div input {
  margin: 0 10px;
}

.containerBody > div label {
  margin: 0;
}

.btn_default {
  background-color: #f38235;
  width: 200px;
  height: 35px;
}

.btn_default:hover {
  background-color: #e0a638;
}

form {
  display: flex;
  align-items: center;
  margin: 0 0 10px;
}

.label {
  margin-bottom: 0;
  font-size: 15px;
  cursor: pointer;
}

.radios {
  width: 40px;
  height: 22px;
}

.text {
  width: 70%;
  border-radius: 5px;
  padding: 20px;
}

.justificar {
  display: flex;
  justify-content: initial;
  flex-direction: column;
  margin-bottom: 0;
}

.justificar textarea {
  width: 100%;
  margin-bottom: 10px;
}

.containerFooter {
  margin: 0 !important;
}

.containers {
  display: flex;
  align-items: center;
}

.fadesTransition-enter-active,
.fadesTransition-leave-active {
  transition: opacity 0.6s;
}
.fadesTransition-enter, .fadesTransition-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
