<template>
<div class="containerCard">
   <div class="card text-center colors" style="width: 18rem;"  v-for="(item, index) in cards" :key="index">
    <div class="card-body" >
      <p class="card-text" v-html="item.text">{{ item.text }}</p>

      <h3 :class="item.classe ? 'position' : ''">Ofereça:</h3>
      <router-link tag="a" :to="`/servico/${item.id}`" :class="item.classe ? 'btn btn-primary position' : 'btn btn-primary '" > 
        {{item.button}}
      </router-link>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    cards: {
      type: Array,
      require: true
    }
  }
}
</script>

<style scoped>
.colors {
  background: #e0a638;
  border-radius: 25px;
  box-shadow: 2px 2px 11px #000;
  margin: 10px 28px 10px 0;
}

a {
   box-shadow: 2px 2px 11px #000000a1;
}

.position {
   position: relative;
   top: 26px;
}

h3 {
  text-decoration: underline;
  font-size: 16px;
}

ul {
  padding: 0;
}

p{  
  font-size: 17px;
  line-height: 1.5;
}

.containerCard {
  display: flex;
}

</style>