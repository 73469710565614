<template>
  <div class="col-md-6 direito py-5" style="overflow: auto;">
    <div class="row">
      <div class="col-md-11">
        <div class="row">
          <div class="col-md-2">
            <p class="titulo_dash">
              Total
              <br />
              <strong>{{ pedido.total | currency }}</strong>
            </p>
          </div>
          <div class="col-md-2">
            <p class="titulo_dash">
              Páginas
              <br />
              <strong>{{ pedido.totalPrints }}</strong>
            </p>
          </div>
        </div>
        <div class="row pays" v-if="!salleFree">
          <div class="row col-md-12">
            <div class="col-md-3 ">
              <input
                type="radio"
                name="inputPay"
                id="money"
                class=""
                checked
                @click="checkeds('money')"
              />
              <label class="titulo_dash mb-1 " for="money">Dinheiro</label>
            </div>
            <div class="col-md-3 ">
              <input
                type="radio"
                name="inputPay"
                id="Cartão"
                @click="checkeds('cartao')"
              />
              <label class="titulo_dash mb-1" for="Cartão">Cartão</label>
            </div>
            <div class="col-md-3 ">
              <input
                type="radio"
                name="inputPay"
                id="PIX"
                @click="checkeds('pix')"
              />
              <label class="titulo_dash mb-1" for="PIX">PIX/Transf</label>
            </div>
            <div class="col-md-3 ">
              <input
                type="radio"
                name="inputPay"
                id="all"
                @click="checkeds('all')"
              />
              <label class="titulo_dash mb-1" for="all">Mais de um</label>
            </div>
          </div>
          <div class="row col-md-12">
            <div :class="'col-md-3 visibled'">
              <money
                key="money"
                v-model="valorCliente"
                v-bind="money"
                class="input_default"
                @blur.native="calcTroco(valorCliente)"
                v-if="pay === 'money' || pay === 'all'"
              ></money>
            </div>
            <div :class="'col-md-3 visibled'">
              <money
                key="cartao"
                v-model="valorClienteCartao"
                v-bind="money"
                class="input_default"
                :disabled="disableInput"
                @blur.native="calcTroco(valorClienteCartao)"
                v-if="pay === 'cartao' || pay === 'all'"
              ></money>
            </div>
            <div :class="'col-md-3 visibled'">
              <money
                v-model="valorClientePiXTrans"
                v-bind="money"
                class="input_default"
                :disabled="disableInput"
                @blur.native="calcTroco(valorClientePiXTrans)"
                v-if="pay === 'pix' || pay === 'all'"
              ></money>
            </div>
            <div
              :class="
                pay === 'all'
                  ? 'valueTotal col-md-3 visibled'
                  : 'valueTotal col-md-3 opacity'
              "
            >
              <strong>{{ valueConvert(valueTotal) }}</strong>
            </div>
          </div>
        </div>
        <p class="titulo_dash mt-3">
          Troco
          <br />
          <strong>{{ troco | currency }}</strong>
        </p>
        <div class="cpfInput">
          <div>
            <p class="titulo_dash mb-1">CPF do Cliente</p>
            <ValidationProvider
              v-slot="{ classes, validate }"
              rules="required|min:11"
              name="CPF"
              tag="div"
              :bails="false"
              class="col-md-12 my-1 p-0"
            >
              <the-mask
                type="text"
                class="input_default form-control"
                mask="###.###.###-##"
                @blur.native="validate($event)"
                v-model="clientDto.CPF"
                placeholder="000.000.000-00"
                :class="classes"
                :masked="false"
                :disabled="disabledCpf"
              />
            </ValidationProvider>
          </div>
          <div class="check">
            <input
              type="checkbox"
              name=""
              id="checkd"
              :disabled="checked"
              @click="changeDisabled"
            />
            <label for="checkd">
              <p class="titulo_dash mb-1">
                Clique aqui se o cliente não souber o CPF {{ CPF }}
              </p>
            </label>
          </div>
        </div>
        <p class="titulo_dash mb-1 success" v-if="status === 'sucess'">
          Oba! O Cliente já Cadastrado!! Atualize os dados, se necessário.
        </p>
        <p class="titulo_dash mb-1 alerts" v-else-if="status === 'notFound'">
          Cliente não está cadastrado ainda! Preencha os dados abaixo:
        </p>
        <div v-if="cadastroForm">
          <form action="" class="form">
            <div>
              <p class="titulo_dash mb-1">Nome Cliente</p>
              <input
                type="text"
                name=""
                id=""
                class="input_default"
                v-model="clientDto.name"
              />
            </div>
            <div>
              <p class="titulo_dash mb-1">Data nascimento</p>
              <input
                type="date"
                name=""
                id=""
                class="input_default"
                v-model="clientDto.birthDate"
              />
            </div>
            <div>
              <p class="titulo_dash mb-1">Sexo</p>
              <select
                name=""
                id=""
                class="input_default"
                v-model="clientDto.genre"
              >
                <option value="1">Masculino</option>
                <option value="2">Feminino</option>
              </select>
            </div>
            <div>
              <p class="titulo_dash mb-1">Celular</p>
              <the-mask
                type="text"
                class="input_default form-control"
                mask="(##) #####-####"
                v-model="clientDto.cellPhone"
                placeholder="(xx) xxxxx-xxxx"
                :masked="false"
                :disabled="disabledCpf"
              />
            </div>
            <div>
              <p class="titulo_dash mb-1">UF</p>
              <select
                name=""
                id=""
                class="input_default"
                v-model="clientDto.stateCity"
              >
                <option
                  v-for="(state, index) in ufStates"
                  :key="index"
                  :value="state"
                  >{{ state }}</option
                >
              </select>
            </div>
            <div class="positioUl">
              <p class="titulo_dash mb-1">Cidade Cliente</p>
              <input
                type="text"
                name=""
                id=""
                class="input_default"
                placeholder="Selecione ou Digite"
                @focus="filterListCity"
                @keyup="filterListCity"
                v-model="clientDto.city"
              />
              <ul name="" id="" class="input_default_ul">
                <li
                  @click="valueCityUl($event.target)"
                  v-for="(state, index) in filterListCitys"
                  :key="index"
                  :id="state.nameCity"
                >
                  {{ state.nameCity }}
                </li>
              </ul>
            </div>
          </form>
          <div class="checkUpdate" v-if="clientDto.id">
            <input type="checkbox" name="" id="Update" @click="updateData" />
            <label for="Update">
              <p class="titulo_dash mb-1">
                Clique aqui se você atualizar algum dado
              </p>
            </label>
          </div>
        </div>
        <p class="titulo_dash mb-1 error" v-if="CPFNull">
          !Antes de concluir, preencha o campo CPF cliente ou clique na opção
          acima
        </p>
        <p class="titulo_dash mb-1 error" v-if="fieldsNull">
          !Verifique os campos acima e preencha antes de seguir
        </p>
        <div class="col-md-3 mr-auto px-0">
          <button
            class="btn_default"
            @click.prevent="concluirVenda()"
            :disabled="disabled"
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
              v-show="disabled"
            ></span>
            {{ !disabled ? "Concluir venda" : "" }}
          </button>
        </div>
        <div class="row mt-3">
          <div class="col-md-4">
            <a href="#" class="titulo_dash" @click.prevent="excludePedido()">
              <i class="fas fa-arrow-left mr-1"></i>Descartar pedido
            </a>
          </div>
          <div class="col-md-4">
            <a href="#" @click.prevent="manterPedido()" class="titulo_dash">
              Manter pedido
              <i class="fas fa-arrow-right ml-1"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <Modal :id="pedido.id" />
  </div>
</template>

<script>
import Modal from "./discard/modal.vue";
import { Money } from "v-money";
import { TheMask } from "vue-the-mask";
import HTTP from "@/api_system";
export default {
  components: { Money, TheMask, Modal },
  props: ["pedido"],
  data() {
    return {
      modalOpen: false,
      valorCliente: 0,
      valorClienteCartao: 0,
      valorClientePiXTrans: 0,
      valuesPays: [],
      disabled: false,
      disabledCpf: false,
      CPF: "",
      CPFNull: false,
      checked: false,
      cadastroForm: false,
      status: "",
      fieldsNull: false,
      registerCustomer: false,
      updateCustumer: false,
      salleFree: false,
      ufStates: [
        "AC",
        "AL",
        "AM",
        "AP",
        "BA",
        "CE",
        "DF",
        "ES",
        "GO",
        "MA",
        "MG",
        "MS",
        "MT",
        "PA",
        "PB",
        "PE",
        "PI",
        "PR",
        "RJ",
        "RN",
        "RO",
        "RR",
        "RS",
        "SC",
        "SE",
        "SP",
        "TO"
      ],
      ufCity: [],
      filterListCitys: [],
      clientDto: {
        id: "",
        name: "",
        birthDate: "",
        genre: "",
        stateCity: "",
        city: "",
        cellPhone: "",
        CPF: ""
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false
      },
      troco: 0,
      pay: "money",
      valTotal: [],
      valTotalAll: false,
      disableInput: false,
      msgErrorPay: ""
    };
  },
  watch: {
    "clientDto.CPF": function(value) {
      if (value.length === 11) {
        this.getCpf();
        this.cadastroForm = true;
        this.checked = true;
      } else {
        this.cadastroForm = false;
        this.status = "";
        this.checked = false;
      }
    },
    "clientDto.stateCity": function(value) {
      this.listCity(value);

      if (value && this.status != "sucess") {
        this.filterListCitys = [];
        this.clientDto.city = "";
      }
    }
  },
  computed: {
    valueTotal() {
      let { valorCliente, valorClienteCartao, valorClientePiXTrans } = this;
      let soma =
        Number(valorCliente) +
        Number(valorClienteCartao) +
        Number(valorClientePiXTrans);
      return soma;
    }
  },
  created() {
    this.filterListCitys = this.ufCity;
    if (this.pedido.total === 0) {
      this.valTotalAll = true;
      this.salleFree = true;
    }
  },
  methods: {
    checkeds(input) {
      this.pay = input;
      this.valorCliente = "";
      this.valorClienteCartao = "";
      this.valorClientePiXTrans = "";
      this.troco = 0;
      this.valTotal = [];
      this.valuesPays = [];
      this.valTotalAll = false;

      const { pay } = this;
      const { total } = this.pedido;

      if (pay === "cartao") {
        this.valorClienteCartao = total;
        this.calcTroco(total);
        this.disableInput = true;
      } else if (pay === "pix") {
        this.valorClientePiXTrans = total;
        this.calcTroco(total);
        this.disableInput = true;
      } else {
        this.disableInput = false;
      }
    },
    valueCityUl(e) {
      let value = e.id;
      this.clientDto.city = value;
      this.filterListCitys = [];
    },
    filterListCity(e) {
      this.filterListCitys = this.ufCity.filter(city => {
        let name = city.nameCity;
        return name.toUpperCase().includes(e.target.value.toUpperCase());
      });
    },
    listCity(stateCity) {
      HTTP.get(`services/app/City/GetByUF?nameUf=${stateCity}`, {
        headers: { Authorization: `Bearer ${this.$store.getters.getterToken}` }
      })
        .then(res => {
          const data = res.data.result;
          this.ufCity = data;
        })
        .catch(err => console.log(err));
    },
    changeDisabled() {
      this.disabledCpf = !this.disabledCpf;
      this.registerCustomer = !this.registerCustomer;
      this.cadastroForm = false;
      this.clientDto.CPF = "";
    },
    updateData() {
      this.updateCustumer = !this.updateCustumer;
    },
    async getCpf() {
      try {
        let result = await HTTP.get(
          `services/app/Client/GetByCPF?cpf=${this.clientDto.CPF}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getterToken}`
            }
          }
        );
        let data = result.data.result;
        this.status = "sucess";
        let bird = data.birthDate;

        this.clientDto.id = data.id;
        this.clientDto.name = data.name;
        this.clientDto.birthDate = bird.substring(0, 10);
        this.clientDto.genre = data.genre;
        this.clientDto.cellPhone = data.cellPhone;
        this.clientDto.stateCity = data.stateCity;
        this.clientDto.city = data.city;
      } catch (error) {
        this.status = "notFound";
        this.clientDto = {
          id: "",
          name: "",
          birthDate: "",
          genre: "",
          stateCity: "",
          city: "",
          cellPhone: "",
          CPF: this.clientDto.CPF
        };
        this.$notify({
          group: "erros",
          type: "error",
          text: `<i class="icon ion-close-circled"></i>Não cadastrado ainda`
        });
      }
    },
    calcTroco(val) {
      if (this.pay === "all") {
        let { total } = this.pedido;
        let { valorCliente } = this;

        if (valorCliente) {
          this.troco = "";
          if (this.valueTotal > total) {
            if (this.valorCliente === this.valueTotal) {
              this.msgErrorPay = "";
              this.troco = this.valueTotal - total;
            } else {
              this.msgErrorPay =
                "Valor informado é maior que o do pedido. Para pagar com mais de um meio, não deve haver troco.";
            }
          } else if (this.valueTotal < total) {
            this.msgErrorPay = "Valor informado é menor que o do pedido.";
          } else {
            this.troco = this.valueTotal - total;
            this.msgErrorPay = "";
          }
        } else {
          //other payments
          this.troco = "";
          let { valorClienteCartao, valorClientePiXTrans } = this;
          let soma = valorClienteCartao + valorClientePiXTrans;

          if (soma > total) {
            this.msgErrorPay =
              "Valor informado é maior que o do pedido. Para pagar com mais de um meio, não deve haver troco.";
          } else if (soma < total) {
            this.msgErrorPay = "Valor informado é menor que o do pedido.";
          } else {
            this.msgErrorPay = "";
          }
        }
      } else if (this.pay === "cartao" || this.pay === "pix") {
        if (val === this.pedido.total) {
          this.troco = 0;
          this.msgErrorPay = "";
        } else {
          this.msgErrorPay = `O Valor informado é ${
            val > this.pedido.total ? "maior" : "menor"
          } que o valor do pedido`;
        }
      } else if (this.pay === "money") {
        if (val < this.pedido.total) {
          this.msgErrorPay = "Valor informado é menor que o valor do pedido!";
        } else {
          this.troco = val - this.pedido.total;
          this.msgErrorPay = "";
        }
      } else {
        this.msgErrorPay = "Meio de pagamento informado não foi identificado.";
      }

      if (this.msgErrorPay != "") {
        this.messageError(this.msgErrorPay);
        this.valTotalAll = false;
      } else {
        this.valTotalAll = true;
      }
    },
    valuePay() {
      this.valuesPays = [];
      const {
        valorCliente,
        valorClienteCartao,
        valorClientePiXTrans,
        valuesPays
      } = this;
      let all = [];
      all.push(valorCliente, valorClienteCartao, valorClientePiXTrans);

      if (valuesPays.length < 3) {
        for (let i = 0; i <= 2; i++) {
          let value = {
            typePayment: i,
            valuePayment: Number(all[i])
          };
          valuesPays.push(value);
        }
      }

      return valuesPays;
    },
    concluirVenda() {
      
      this.$store.commit("payValue", this.valuePay());
     
      if (this.registerCustomer === true) {
        this.$store.commit("userCliente", null);
      } else {
        if (
          this.clientDto.name === "" ||
          this.clientDto.city === "" ||
          this.clientDto.cellPhone.length < 10
        ) {
          if (
            this.clientDto.cellPhone.length < 10 &&
            this.cadastroForm === true
          ) {
            this.fieldsNull = true;
            this.messageError("!Numero de Telefone inválido");
          } else {
            if (this.cadastroForm === true) {
              this.fieldsNull = true;
            }

            this.messageError(
              "!Verifique os campos acima e preencha antes de seguir"
            );
          }

          this.$store.commit("userCliente", null);
        } else {
          this.$store.commit("userCliente", this.clientDto);
        }
      }

      this.$store.commit("updateClienteData", this.updateCustumer);
      this.$store.commit("cpfUndefined", this.registerCustomer);

      if (this.clientDto.CPF.length < 11 && this.disabledCpf === false) {
        this.CPFNull = true;
      } else {
        this.CPFNull = false;
        this.fieldsNull = false;

        if (this.valTotalAll) {
          this.disabled = true;

          HTTP.post(
            `services/app/Order/CheckFinishedOrder?orderId=${this.$store.state.pedidos.pedidos.id}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getterToken}`
              }
            }
          )
            .then(res => {
              console.log(res, this.$store.state.pedidos.pedidos.id, this.pedido.id);
              this.$store
                .dispatch("finishPedido", this.pedido.id)
                .then(resolve => {
                  this.messageSucess("Pedido finalizado!");
                  setTimeout(() => {
                    this.$store.commit("set_pedido", "");
                    this.$store.commit('changeOrderId', '')
                    this.$router.push("/pedidos");
                  }, 2000);
                })
                .catch(reject => {
                  this.disabled = false;
                  console.log(reject.response.data.error.message);
                  this.fieldsNull = true;
                  this.messageError(
                    "Valor maior que o pedido. Para pagar com mais de um meio, não deve haver troco."
                  );
                });
            })
            .catch(err => {
              this.disabled = false;
              this.$notify({
                group: "erros",
                type: "error",
                text: `<i class="icon ion-close-circled"></i>O pedido solicitado já foi finalizado!`
              });

              setTimeout(() => {
                this.$store.commit("set_pedido", "");
                this.$router.push("/pedidos");
              }, 2000);
              console.log(err);
            });
        } else {
          this.messageError(
            `${
              this.msgErrorPay === ""
                ? "Insira o valor e o tipo de pagamento"
                : this.msgErrorPay
            }`
          );
        }
      }
    },
    excludePedido() {
      $("#showModal").modal("show");
    },
    manterPedido() {
      this.$notify({
        group: "erros",
        type: "sucess",
        text: `<i class="icon ion-close-circled"></i>Pedido Salvo!`
      });

      setTimeout(() => {
        this.$store.commit("set_pedido", "");
        this.$router.push("/pedidos");
      }, 2000);
    },
    messageError(message) {
      return this.$notify({
        group: "erros",
        type: "error",
        text: `<i class="icon ion-close-circled"></i>${message}`
      });
    },
    messageSucess(message) {
      return this.$notify({
        group: "erros",
        type: "sucess",
        text: `<i class="icon ion-close-circled"></i>${message}`
      });
    }
  }
};
</script>

<style scoped>
.input_default {
  height: 30px;
  background-color: #6198c2 !important;
  border: 1px solid #e3e3e3 !important;
  color: #fff;
}

.selects {
  color: #fff !important;
  font-weight: 500;
  font-size: 14px;
  min-width: 200px;
}

.positioUl {
  position: relative;
}

.input_default_ul {
  text-decoration: none;
  max-height: 400px;
  width: auto;
  width: 182px;
  overflow: auto;
  padding: 0%;
  margin: 0;
  border-right-style: solid !important;
  border-left-style: solid !important;
  border-right-color: #fff !important;
  border-left-color: #ffff !important;
  border-right-width: 1px !important;
  border-left-width: 2px !important;
  color: #fff;
  position: absolute;
  z-index: 1;
  background: #2474b2;
}

.input_default_ul li {
  display: block;
  color: #fff;
  padding: 10px;
  border-bottom: 1px solid #fff;
  width: auto;
}

.input_default_ul li:hover {
  background-color: #14476e;
  cursor: pointer;
}

.direito {
  background-color: #2474b2;
}
.titulo_dash {
  line-height: 20px;
}
.btn_default {
  background-color: #f38235;

  height: 35px;
}
.btn_default:hover {
  background-color: #e0a638;
}
a:hover {
  color: #ffffff;
}
.cpfInput {
  display: flex;
  align-items: center;
  margin: 0 0 20px;
}
.check {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 25px;
  margin-left: 13px;
}
.check label,
.check p {
  margin: 0;
}
.check label {
  margin: 0 7px;
  cursor: pointer;
}
#checkd {
  width: 30px;
  height: 20px;
  cursor: pointer;
}

.form {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.checkUpdate {
  display: flex;
  align-items: center;
}

#Update {
  width: 30px;
  height: 20px;
  cursor: pointer;
}

.checkUpdate p {
  margin: 12px;
}

.form div {
  margin: 10px 10px 10px 0;
}
.form select {
  padding-left: 25px;
}

.success {
  color: #44ff44;
}
.alerts {
  color: #e4f124;
}
.error {
  color: #e5576b;
}
.pays input[type="radio"] {
  position: relative;
  right: 5px;
  top: 2px;
  cursor: pointer;
}

.pays label {
  cursor: pointer;
}

.payInputs ~ div {
  background: olivedrab;
}

.opacity {
  transition: 0.1s linear;
  opacity: 0;
  cursor: default;
  position: relative;
  z-index: -1;
}

.visibled {
  opacity: 1;
  z-index: 1;
}

.valueTotal {
  display: flex;
  align-items: center;
  color: #fff;
}
</style>
